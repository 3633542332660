import React, { useState, useEffect } from "react";
import Layout from "../Layouts/Layout";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FaMobileAlt } from "react-icons/fa";
import Loader from "../Components/Loader";
import axios from "axios";
import {
  get_activation_api,
  get_charging_api,
  get_deactivation_api,
  get_history_api,
} from "../Services/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import classes from "./Home.module.css";
import Select from "react-select";
import * as XLSX from "xlsx";

const UserHistory = () => {
  const [deactivationTableData, setDeactivationTableData] = useState([]);
  const [number, setNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [chargingShowTable, setChargingShowTable] = useState(false);
  const [activationShowTable, setActivationShowTable] = useState(false);
  const [deactivationShowTable, setDeactivationShowTable] = useState(false);

  const [activationTableData, setActivationTableData] = useState([]);
  const [chargingTableData, setChargingTableData] = useState([]);

  // console.log(chargingShowTable)

  const options = [
    { value: "Activation History", label: "Activation" },
    { value: "Deactivation History", label: "Deactivation" },
    { value: "Charging History", label: "Charging" },
  ];

  const [selectedOption, setSelectedOption] = useState({
    value: "Activation History",
    label: "Activation",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(selectedOption, "gxfcghj");

    setLoading(true);

    if (number == "") {
      toast.error("Please Input Number first!!");
      setLoading(false);
      return;
    }

    const data = {
      ani: number,
    };

    try {
      if (selectedOption.label == "Activation") {
        const res = await axios.post(get_activation_api, data);
        console.log(res.data.result);
        if (res.data.result.length == 0) {
          toast.success("No Data Found !!");
        }

        setActivationTableData(res.data.result);
        setActivationShowTable(true);
        setDeactivationShowTable(false);
        setChargingShowTable(false);
        setLoading(false);

        return;
      } else if (selectedOption.label == "Deactivation") {
        const res = await axios.post(get_deactivation_api, data);
        console.log(res.data.result);
        if (res.data.result.length == 0) {
          toast.success("No Data Found !!");
        }

        setDeactivationTableData(res.data.result);
        setDeactivationShowTable(true);
        setActivationShowTable(false);
        setChargingShowTable(false);
        setLoading(false);

        return;
      } else {
        const res = await axios.post(get_charging_api, data);
        console.log(res.data.result);
        if (res.data.result.length == 0) {
          toast.success("No Data Found !!");
        }

        setDeactivationShowTable(false);
        setActivationShowTable(false);
        setChargingTableData(res.data.result);
        setChargingShowTable(true);
        setLoading(false);

        return;
      }
    } catch (err) {
      console.log(err);
      toast.error(err.response.data.message);
    }
    setLoading(false);

    setNumber("");
    setNumber("");
  };

  const handleDownload = async () => {
    let ws;
    if (selectedOption.label == "Activation") {

       ws = XLSX.utils.json_to_sheet(activationTableData.map(({ id,ani,svc_name,status_name,sub_date_time,last_billed_date,type_event }) => ({
        id,
        ani,
        svc_name,
        status_name,
        sub_date_time: sub_date_time.split("T")[0], // Assigning date after modification
        last_billed_date: last_billed_date.split("T")[0], // Assigning date after modification
        type_event
})));


const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    XLSX.writeFile(wb, "Activation_history.xlsx");



    } else if (selectedOption.label == "Deactivation") {
       ws = XLSX.utils.json_to_sheet(deactivationTableData.map(({ id,ani,svc_name,status_name,sub_date_time,updated_at,unsub_date_time }) => ({
        id,
        ani,
        svc_name,
        status_name,
        sub_date_time: sub_date_time.split("T")[0], // Assigning date after modification
        updated_at: updated_at.split("T")[0], // Assigning date after modification
        unsub_date_time: unsub_date_time.split("T")[0]
})));

const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    XLSX.writeFile(wb, "Deactivation_history.xlsx");



    } else {
       ws = XLSX.utils.json_to_sheet(chargingTableData.map(({ id,ani,svc_name,result_name,lastBillingDate,processDateTime }) => ({
        id,
        ani,
        svc_name,
        result_name,
        lastBillingDate: lastBillingDate.split("T")[0], // Assigning date after modification
        ChargingDate: processDateTime.split("T")[0]
})));

const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    XLSX.writeFile(wb, "Charging_history.xlsx");
    }

   
  };

  return (
    <div>
      <Layout>
        <div className="dark:text-white flex flex-col px-2 w-full">
          <div className="lg:hidden md:hidden">
            <h1 className="flex justify-center items-center text-gray-600 underline font-bold text-lg">
              Bobble History Logs
            </h1>
          </div>
          <h1 className="flex justify-center items-center text-emerald-600 font-bold text-lg">
            {" "}
            Subscription History
          </h1>
          <form
            onSubmit={handleSubmit}
            className="flex justify-center items-center"
          >
            <div className="flex flex-col  justify-center items-center bg-blue-50 shadow-3xl lg:w-[400px] lg:h-[250px] w-[250px] h-[280px] rounded-lg">
              <div className="flex flex-col gap-3 text-black">
                <label className="text-xl lg:mt-2 mt-3 items-start text-black">
                  Enter Number
                </label>
                <div className="flex flex-row relative">
                  <FaMobileAlt className="absolute top-1/2 right-3 transform -translate-y-1/2 text-black" />
                  <input
                    placeholder="Enter Number"
                    name="number"
                    value={number}
                    onChange={(e) => {
                      setNumber(e.target.value);
                    }}
                    className="border-2 border-gray-300 bg-white text-black rounded-lg lg:px-3 lg:py-2 px-4 py-2 lg:w-[200px] w-[180px]"
                  />
                </div>

                <Select
                  defaultValue={selectedOption}
                  onChange={setSelectedOption}
                  options={options}
                  style={{color:'black'}}
                />
              </div>

              <div className="w-24 lg:mt-6 mt-8 bg-emerald-600 text-white rounded-lg lg:px-3 lg:py-2 px-3 py-1">
                <button type="submit" className="w-full text-center">
                  Submit
                </button>
              </div>
            </div>
          </form>
          {loading ? (
            <Loader />
          ) : (
            <>
              {chargingShowTable && (
                <>
                <div className='w-full flex justify-between items-center '>
                <h1 className='text-xl font-bold'>Charging History :</h1>
                <img src="https://static.vecteezy.com/system/resources/previews/027/179/360/non_2x/microsoft-excel-icon-logo-symbol-free-png.png" alt="" className='rounded-full h-16 w-16 border border-gray-200 shadow-3xl cursor-pointer' onClick={handleDownload} />
                </div>
                  <DataTable
                    value={chargingTableData}
                    stripedRows
                    emptyMessage="No data found"
                    responsive
                    scrollable
                    scrollHeight="500px"
                    tableStyle={{ minWidth: "10rem" }}
                    className={classes.tbl}
                  >
                    <Column
                      field="ani"
                      header="ANI"
                      headerStyle={{
                        backgroundColor: "#e9f7f1",
                        padding: "1rem",
                        textAlign: "center",
                      }}
                    />
                    <Column
                      field="svc_name"
                      header="SERVICE"
                      headerStyle={{
                        backgroundColor: "#e9f7f1",
                        padding: "1rem",
                        textAlign: "center",
                      }}
                    />
                    <Column
                      field="result_name"
                      header="RESULT"
                      headerStyle={{
                        backgroundColor: "#e9f7f1",
                        padding: "1rem",
                        textAlign: "center",
                      }}
                    />
                    <Column
                      field="lastBillingDate"
                      header="LAST BILLING DATE"
                      headerStyle={{
                        backgroundColor: "#e9f7f1",
                        padding: "1rem",
                        textAlign: "center",
                      }}
                      body={(rowData) =>
                        rowData.lastBillingDate
                          ? rowData.lastBillingDate
                              .split("T")[0]
                              .split("-")
                              .join("/")
                          : "null"
                      }
                    />
                    <Column
                      field="processDateTime"
                      header="Charged Date"
                      headerStyle={{
                        backgroundColor: "#e9f7f1",
                        padding: "1rem",
                        textAlign: "center",
                      }}
                      body={(rowData) =>
                        rowData.processDateTime
                          ? rowData.processDateTime
                              .split("T")[0]
                              .split("-")
                              .join("/")
                          : "null"
                      }
                    />
                    {/* <Column field="expires_at" header="UnsubDate"
                    headerStyle={{ backgroundColor: "#e9f7f1",padding: "1rem",textAlign: "center",
                    }}
                    body={(rowData) =>rowData.expires_at ? rowData.expires_at.split("T")[0].split("-").join("/"): ""}
                  /> */}
                  </DataTable>
                </>
              )}

              {deactivationShowTable && (
                <> <div className='w-full flex justify-between items-center '>
                <h1 className='text-xl font-bold'>Deactivation History :</h1>
                <img src="https://static.vecteezy.com/system/resources/previews/027/179/360/non_2x/microsoft-excel-icon-logo-symbol-free-png.png" alt="" className='rounded-full h-16 w-16 border border-gray-200 shadow-3xl cursor-pointer' onClick={handleDownload} />
                </div>
                <DataTable
                  value={deactivationTableData}
                  stripedRows
                  emptyMessage="No data found"
                  responsive
                  scrollable
                  scrollHeight="500px"
                  tableStyle={{ minWidth: "10rem" }}
                  className={classes.tbl}
                >
                  <Column
                    field="ani"
                    header="ANI"
                    headerStyle={{
                      backgroundColor: "#e9f7f1",
                      padding: "1rem",
                      textAlign: "center",
                    }}
                  />
                  <Column
                    field="svc_name"
                    header="SERVICE"
                    headerStyle={{
                      backgroundColor: "#e9f7f1",
                      padding: "1rem",
                      textAlign: "center",
                    }}
                  />
                  <Column
                    field="status_name"
                    header="STATUS"
                    headerStyle={{
                      backgroundColor: "#e9f7f1",
                      padding: "1rem",
                      textAlign: "center",
                    }}
                  />
                  <Column
                    field="sub_date_time"
                    header="SUBSCRIPTION STARTED"
                    headerStyle={{
                      backgroundColor: "#e9f7f1",
                      padding: "1rem",
                      textAlign: "center",
                    }}
                    body={(rowData) =>
                      rowData.sub_date_time
                        ? rowData.sub_date_time
                            .split("T")[0]
                            .split("-")
                            .join("/")
                        : "null"
                    }
                  />
                  <Column
                    field="updated_at"
                    header="Last Charged"
                    headerStyle={{
                      backgroundColor: "#e9f7f1",
                      padding: "1rem",
                      textAlign: "center",
                    }}
                    body={(rowData) =>
                      rowData.updated_at
                        ? rowData.updated_at.split("T")[0].split("-").join("/")
                        : "null"
                    }
                  />
                  <Column
                    field="unsub_date_time"
                    header="Unsub Date"
                    headerStyle={{
                      backgroundColor: "#e9f7f1",
                      padding: "1rem",
                      textAlign: "center",
                    }}
                    body={(rowData) =>
                      rowData.unsub_date_time
                        ? rowData.unsub_date_time
                            .split("T")[0]
                            .split("-")
                            .join("/")
                        : "null"
                    }
                  />
                </DataTable>
                </>
              )}

              {activationShowTable && (
                <>
                <div className='w-full flex justify-between items-center '>
                <h1 className='text-xl font-bold'>Activation History :</h1>
                <img src="https://static.vecteezy.com/system/resources/previews/027/179/360/non_2x/microsoft-excel-icon-logo-symbol-free-png.png" alt="" className='rounded-full h-16 w-16 border border-gray-200 shadow-3xl cursor-pointer' onClick={handleDownload} />
                </div>
                <DataTable
                  value={activationTableData}
                  stripedRows
                  emptyMessage="No data found"
                  responsive
                  scrollable
                  scrollHeight="500px"
                  tableStyle={{ minWidth: "10rem" }}
                  className={classes.tbl}
                >
                  <Column
                    field="ani"
                    header="ANI"
                    headerStyle={{
                      backgroundColor: "#e9f7f1",
                      padding: "1rem",
                      textAlign: "center",
                    }}
                  />
                  <Column
                    field="svc_name"
                    header="SERVICE"
                    headerStyle={{
                      backgroundColor: "#e9f7f1",
                      padding: "1rem",
                      textAlign: "center",
                    }}
                  />
                  <Column
                    field="status_name"
                    header="STATUS"
                    headerStyle={{
                      backgroundColor: "#e9f7f1",
                      padding: "1rem",
                      textAlign: "center",
                    }}
                  />
                  <Column
                    field="sub_date_time"
                    header="SUBSCRIPTION STARTED"
                    headerStyle={{
                      backgroundColor: "#e9f7f1",
                      padding: "1rem",
                      textAlign: "center",
                    }}
                    body={(rowData) =>
                      rowData.sub_date_time
                        ? rowData.sub_date_time
                            .split("T")[0]
                            .split("-")
                            .join("/")
                        : ""
                    }
                  />
                  <Column
                    field="last_billed_date"
                    header="Last Charged"
                    headerStyle={{
                      backgroundColor: "#e9f7f1",
                      padding: "1rem",
                      textAlign: "center",
                    }}
                    body={(rowData) =>
                      rowData.last_billed_date
                        ? rowData.last_billed_date
                            .split("T")[0]
                            .split("-")
                            .join("/")
                        : "null"
                    }
                  />
                  <Column
                    field="type_event"
                    header="Type Event"
                    headerStyle={{
                      backgroundColor: "#e9f7f1",
                      padding: "1rem",
                      textAlign: "center",
                    }}
                    // body={(rowData) =>rowData.expires_at ? rowData.expires_at.split("T")[0].split("-").join("/"): ""}
                  />
                </DataTable>
                </>
              )}
            </>
          )}
        </div>
      </Layout>
    </div>
  );
};

export default UserHistory;
